<template>
  <footer>
    <div class="container">
      <div class="left">
        <div class="col-1">
          <router-link class="header" :to="{ name: 'Home' }">Comperato</router-link>
          <ul>
            <li>
              <a href="https://youtube.com/@Comperato"><youtube class="svg-icon"/></a>
            </li>
            <li>
              <a href="https://twitter.com/ComperatoWeb"><twitter class="svg-icon"/></a>
            </li>
            <li>
              <a href="https://www.instagram.com/comparocity"><instagram class="svg-icon"/></a>
            </li>
            <li>
              <a href="https://www.tiktok.com/@comperato"><tiktok class="svg-icon"/></a>
            </li>
            <li>
              <a href="https://www.facebook.com/people/Comperato/100095058992298/"><facebook class="svg-icon"/></a>
            </li>
          </ul>
        </div>
        <div class="col-2">
          <ul>
            <router-link class="link" :to="{ name: 'Home' }">Home</router-link>
            <router-link class="link" :to="{ name: 'Blogs' }">Blogs</router-link>
            <router-link  class="link" :to="{ name: 'Contact' }">Contact</router-link>
            <router-link v-if="!user" class="link" :to="{ name: 'Register' }">Register</router-link>
          </ul>
        </div>
      </div>
      <div class="right">
        <p>Copyright 2023 All Rights Reserved</p>
      </div>
    </div>
  </footer>
</template>

<script>
import youtube from "../assets/Icons/youtube-brands.svg";
import twitter from "../assets/Icons/twitter-brands.svg";
import instagram from "../assets/Icons/instagram-brands.svg";
import tiktok from "../assets/Icons/tiktok-icon.svg";
import facebook from "../assets/Icons/facebook-icon.svg";
export default {
    name: "footer-vue",
    components: {
        youtube,
        twitter,
        instagram,
        facebook,
        tiktok,
    },
    computed: {
        user() {
            return this.$store.state.user
        },
        admin() {
            return this.$store.state.profileAdmin
        },
    },
};
</script>

<style lang="scss" scoped>
footer {
  margin-top: auto;
  padding: 100px 25px;
  background-color: #303030;
  .container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    @media (min-width: 800px) {
      flex-direction: row;
      gap: 0px;
      justify-content: center;
      align-items: center;
    }
    div {
      display: flex;
      flex: 1;
    }
    .left {
      gap: 32px;
      color: #fff;
      display: flex;
      flex-direction: column;
      @media (min-width: 800px) {
        flex-direction: row;
        align-items: initial;
        gap: 0;
      }
      .header {
        text-align: center;
        font-size: 24px;
        color: #fff;
        margin-bottom: 16px;
        text-decoration: none;
        font-weight: 600;
        @media (min-width: 800px) {
          text-align: initial;
        }
      }
      ul {
        gap: 16px;
        list-style: none;
        display: flex;
      }
      .col-1,
      .col-2 {
        gap: 32px;
        display: flex;
        flex: 1;
        align-self: center;
        ul {
          margin: 0 0 0 0;
          padding: 0 0 0 0;
        }
        @media (min-width: 800px) {
          gap: 0;
        }
      }
      .col-1 {
        flex-direction: column;
        align-items: center;
        h2 {
          text-align: center;
          @media (min-width: 800px) {
            text-align: initial;
          }
        }
        ul {
          margin-top: auto;
          li {
            display: flex;
            align-items: center;
            .svg-icon {
              width: 24px;
              height: auto;
              color: #fff;
            }
          }
        }
      }
      .col-2 {
        ul {
          height: 100%;
          justify-content: center;
          flex-direction: row;
          flex-wrap: wrap;
          @media (min-width: 800px) {
            flex-direction: column;
          }
          .link {
            font-size: 16px;
            font-weight: 500;
            color: #fff;
            text-decoration: none;
          }
        }
      }
    }
    .right {
      gap: 32px;
      color: #fff;
      align-items: center;
      flex-direction: column;
      @media (min-width: 800px) {
        align-items: flex-end;
        gap: 0;
      }
    }
    p {
      margin-top: auto;
    }
  }
}
</style>
