<template>
  <div class="home">
    <Slider/>
    <BlogPost v-if="!user" :post="welcomeScreen"/>
    <PostCollection :posts="mainposts"/>
    <BlogCardCollection :blogs="blogCards" />
</div>
</template>

<script>
import "../firebase/firebaseInit";
import db from "../firebase/firebaseInit";
import BlogPost from '../components/BlogPost.vue';
import PostCollection from '../components/PostCollection.vue';
import Slider from '../components/Slider.vue';
import BlogCardCollection from '../components/BlogCardCollection.vue';
export default {
  name: "Home",
  components: {
    BlogPost,
    Slider,
    PostCollection,
    BlogCardCollection
  },
  metaInfo: function() {
      return {
          title: "Comperato",
          meta: [
              { name: 'description', content: "Comperato is a website where two subject compared. You may find content on various subjects of your interest."},
              { name: "keywords", content: "comperato, compare, collide, blog, comparing, debate, discuss, better, worst"},
          ]
      }
  },
  // the data should be (mostly) fetched from firebase
  data() {
    //const contentdb = db.collection("contentRefs");
    //const contents = contentdb.orderBy("date").limit(3);

    var highlighlst = [];
    var bloglst = [];

    db.collection("highligths").limit(3)
    .get()
    .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            highlighlst.push(doc.data())
            //console.log(doc.id, " => ", doc.data());
        });
    })
    .catch((error) => {
        console.log("Error getting documents: ", error);
    });

    db.collection("contentRefs").limit(8).orderBy("date", "desc")
    .get()
    .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            bloglst.push(doc.data())
            //console.log(doc.id, " => ", doc.data());
        });
    })
    .catch((error) => {
        console.log("Error getting documents: ", error);
    });

    return {
      welcomeScreen: {
        // if first time an opening post below the slides
        title: "Welcome to Comperato",
        desc: "We compare",
        welcomeScreen: true,
        coverPhoto: "beautiful-stories.jpg",
      },
      mainposts: highlighlst,
      stayTuned: {
        // if first time a link to sign-up
        title: "title here",
        desc: "content here",
        welcomeScreen: true
      },
      blogCards: {
        title: "View recent posts",
        blogs: bloglst,
      },
      fls: {
        title: "View recent posts",
        blogs: [
        {
          title: "title here",
          date: "date here",
          editor: "editor here",
          coverPhoto: "../assets/blogPhotos/coding.jpg",
          contentLink: "content link here",
        },
        {
          title: "title here",
          date: "date here",
          editor: "editor here",
          coverPhoto: "../assets/blogPhotos/coding.jpg",
          contentLink: "content link here",
        },
        {
          title: "title here",
          date: "date here",
          editor: "editor here",
          coverPhoto: "../assets/blogPhotos/coding.jpg",
          contentLink: "content link here",
        },
        {
          title: "title here",
          date: "date here",
          editor: "editor here",
          coverPhoto: "../assets/blogPhotos/coding.jpg",
          contentLink: "content link here",
        },
        {
          title: "title here",
          date: "date here",
          editor: "editor here",
          coverPhoto: "../assets/blogPhotos/coding.jpg",
          contentLink: "content link here",
        },
        {
          title: "title here",
          date: "date here",
          editor: "editor here",
          coverPhoto: "../assets/blogPhotos/coding.jpg",
          contentLink: "content link here",
        },
        
      ]
      }
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    toContentRefs(posts) {
      var retList = [];
      posts.forEach(post => {
        db.collection("contentRefs").where("ref", "==", post.ref).get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            retList.push(doc.data());
          })
        });
      });
      return retList;
    }
  },
  
};
</script>

<style lang="scss" scoped>

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Quicksand", sans-serif;
}
.home {
  margin-top: 30px;
  margin-bottom: 30px;
}
</style>
