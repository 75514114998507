<template>
<div class="slideitem" v-bind:style="{
    'backgroundImage': 'url('+snap.coverPhoto+')',
    'background-size': 'cover',
    'background-repeat': 'no-repeat',
    'background-position': 'center',
}" @click.prevent="sendToPage">
    <div class="divonimage">
        <p class="titleclass"> {{snap.title}} </p>
    </div>
    
</div>
</template>

<script>
import db from "../firebase/firebaseInit";
import "../firebase/firebaseInit";
export default {
    name: "SlideItem",
    props: ["post"],
    // post elemnani bir contentRefs elemani alacak onu kullanarak resim yuklenecek ve description title vesaire verilecek
    data() {
        return {
            snap: null,
        }
    },
    methods: {
        fetchSlide() {
            db.collection("contentRefs").doc(this.post.ref).onSnapshot((doc) => {
                this.snap = doc.data();
            })
        },
        sendToPage() {
            console.log("send to page");
            console.log(this.snap);
            this.$router.push({ name: 'ViewBlog', params: { blogname: this.snap.title.split(" ").join("-").toLowerCase(), topicname: this.snap.topic }, query: {id: this.snap.contentLink} })
        },
    },
    created() {
        this.fetchSlide();
    }
}
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Quicksand", sans-serif;
}
.slideitem {
    width: 70%;
    height: 100%;
    color: black;
    display: flex;
    flex-direction: column;;
    justify-content: flex-end;
    @media (max-width: 700px) {
        width: 100%;;
    }

    .divonimage {
        background-color: rgba($color: white, $alpha: 0.5);
        color: white;
        font-weight: 900;
    }

    .titleclass {
        font-size: 54px;
    }
}
</style>