<template>
<div>
    <h2>Contact Page</h2>
</div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Quicksand", sans-serif;
}

</style>