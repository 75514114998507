<template>
<div class="ViewBlog">
    <article>
        <h1 class="title"> {{title}} </h1>
        <p class="editor">Editor:  {{ editor }} </p>
        <div class="lineshow">
            <button :disabled="isDisabled" @click.prevent="likeFunction" class="no-button">       
                <like v-if="liked" class="lki like-icon-yes"/>
                <like v-else class="lki like-icon-not"/>
                Like(s): {{ likes }}
            </button>
            <p class="date"> {{ uploaddate }} </p>
            <p>Visits: {{ views }} </p>
        </div>
        <img class="image" :src="this.img" :alt="title" />
        <p class="description"> {{ desc }} </p>
        <div class="content" v-html="content"> </div>
    </article>
    <div class="comments">
        <h2>What do you think?</h2>
        <textarea maxlength="400" type="text" name="" id="" placeholder="Write your own comment..." class="commentInput" v-model="yourComment"></textarea>
        <p v-if="isCommentMaxed" class="messageText">Your comment cannot be longer than 400 characters</p>
        <p v-if="isDisabled" class="messageText">In order to comment, you should sign in!</p>
        <button :disabled="isDisabled" class="sendCommentBtn" @click.prevent="sendComment">Send</button>
    </div>
    <div class="comments">
        <h2>Comments</h2>
        <li v-for="(item, index) in comments" :key="item.id">
            <CommentCard :comment="item" :key="index"/>
        </li>
    </div>
</div>
</template>

<script>
import like from "../assets/Icons/likeicon.svg";
import "../firebase/firebaseInit";
import firebase from "firebase";
import CommentCard from "../components/CommentCard.vue";
import db from "../firebase/firebaseInit";
export default {
    components: {
        like,
        CommentCard,
    },

    props: ["dataProp"],

    data() {
        return {
            title: "",
            content: "",
            likes: 0,
            uploaddate: "",
            views: 0,
            editor: "",
            desc: "",
            img: "",
            liked: false,
            yourComment: "",
            comments: [],
            fetchComments: db.collection('contents/'+this.blogid+"/comments").orderBy("date", "desc").limit(10),
            lastComment: null,
            docRef: null,
        };
    },

    mounted() {
        
    },

    updated() {
        this.checkLiked();
        //window.addEventListener("scroll", this.handleScroll);
    },

    created() {
        this.getData();
        this.getComments();
        this.checkLiked();
        this.$route.meta.name = this.title;
        this.$route.meta.description = this.desc;
        this.$route.meta.author = this.editor;
    },

    computed: {
        isDisabled() {
            if (this.$store.state.user == null) {
                return true;
            } else {
                return false;
            }
        },
        isCommentMaxed() {
            if (this.yourComment.length == 400) {
                return true;
            } else {
                return false;
            }
        },
        getDesc() {
            return this.desc;
        }
    },

    methods: {
        sendComment() {
            const userId = this.$store.state.id;
            const username = this.$store.state.username;
            const theComment = this.yourComment;
            db.collection("contents").doc(this.$route.query.id).collection("comments").add({
                userId: userId,
                username: username,
                content: theComment,
                date: firebase.firestore.Timestamp.fromDate(new Date()),
            }).then((doc) => {
                console.log("You have send following comment:");
                console.log(doc);
                this.comments = [{
                    data() {
                        return {
                            userId: userId,
                            username: username,
                            content: theComment,
                            date: {
                                toDate() {
                                    return {
                                        toDateString() {
                                            return "now";
                                        },
                                    };
                                },
                            },
                        };
                    },
                    id: doc.id,
                }, ...this.comments];
            }).catch((err) => {
                alert("Error: Sending comment", err.messageText);
                console.log(err);
            });
        },
        likeFunction() {
            this.liked = !this.liked;
            if (this.liked) {
                db.collection("users").doc(this.$store.state.id)
                .update({
                    liked: firebase.firestore.FieldValue.arrayUnion(this.$route.query.id)
                });
                db.collection("contents").doc(this.$route.query.id).set({
                    likes: this.likes + 1,
                }, { merge: true }).then(() => {
                    this.likes = this.likes + 1;
                });
            } else {
                db.collection("users").doc(this.$store.state.id)
                .update({
                    liked: firebase.firestore.FieldValue.arrayRemove(this.$route.query.id)
                });
                db.collection("contents").doc(this.$route.query.id).set({
                    likes: this.likes - 1,
                }, { merge: true }).then(() => {
                    this.likes = this.likes - 1;
                });
            }
        }, 
        checkLiked() {
            db.collection("users").doc(this.$store.state.id)
            .get().then((doc) => {
                if (doc.data().liked.includes(this.$route.query.id)) {
                    this.liked = true;
                } else {
                    this.liked = false;
                }
            });
        },
        async getData() {
            const blgid = this.$route.query.id;
            this.docRef = db.collection("contents").doc(blgid);

            this.docRef.get().then((doc) => {
                if (doc.exists) {
                    console.log("Document data:", doc.data());
                    this.title = doc.data().title;
                    this.content = doc.data().content;
                    this.likes = doc.data().likes;
                    this.views = doc.data().views;
                    this.editor = doc.data().editor;
                    var seconds = doc.data().date;
                    const date = new Date(seconds.toDate())
                    this.uploaddate = date.toDateString()
                    this.desc = doc.data().desc;
                    this.img = doc.data().coverPhoto;
                    this.docRef.set({
                        views: doc.data().views+1,
                    }, { merge: true }).then(() => {
                        this.views = this.views + 1;
                    });
                } else {    
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            }).catch((error) => {
                console.log("Error getting document:", error);
            });    

            this.fetchComments = this.docRef.collection("comments").orderBy("date", "desc").limit(10);
        },

        getComments() {
            this.fetchComments.get().then((documentSnapshots) => {
                this.lastComment = documentSnapshots.docs[documentSnapshots.docs.length-1];
                this.fetchComments = this.docRef.collection("comments").orderBy("date", "desc").startAfter(this.lastComment).limit(10),
                documentSnapshots.forEach((doc) => {
                    this.comments = [...this.comments, doc];
                })
                window.addEventListener("scroll", this.handleScroll);
            }).catch((err) => {
                console.log(err);
            });
        },

        resetComments() {
            this.comments = [];
        },

        handleScroll() {
            if (window.scrollY + window.innerHeight >= document.body.scrollHeight-150) {
                window.removeEventListener("scroll", this.handleScroll);
                this.getComments();
            }
        },
        getDescFunction() {
            return this.desc;
        }
    },
    metaInfo: function() {
        return {
            title: this.title,
            meta: [
                { name: 'description', content: this.desc },
                { name: 'author', content: this.editor },
                { name: 'title', content: this.title },
                { property: 'og:title', content: this.title },
                { property: 'og:description', content: this.desc },
                { property: "og:image", content: this.img },
                { property: "og:type", content: "article" },
                { property: "og:url", content: window.location.href },
            ]
        }
    }

};
</script>

<style lang="scss">
    body { 
        margin: 8px;
    }
    
    .ViewBlog { 
        padding-top: 30px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 30px;
    


        .messageText{
            font-size: 12px;
            color: red;
            font-weight: 500;
        }
    
        .commentInput {
            width: 100%;
            padding: 20px;
            margin-top: 20px;
            font-size: 16px;
            font-weight: 600;
            color: #303030;
            height: 120px;
        }
    
        .sendCommentBtn {
            background-color: #303030;
            color: white;
            font-weight: 900;
            border: none;
            margin-top: 20px;
            padding: 10px;
            font-size: 18px;
        }
    
        .sendCommentBtn:hover {
            border: 3px solid #303030;
            border-radius: 3px;
            background-color: white;
            color: #303030;
            padding: 7px;
        }
    
        .no-button {
            text-decoration: none;
            border: none;
            background-color: inherit;
            font-size: 16px;
        }
    
        .no-button:hover {
            .lki {
                filter: opacity(0.7);
            }
        }
    
        .lki {
            height: 14px;
        }
    
        .like-icon-yes {
            fill: red;
        }
        .like-icon-not {
            fill: black;
        }
        .title {
            font-size: 44px;
            margin: auto;
            text-align: center;
        }
        
        .editor {
            text-align: center;
            font-size: 14px;
            color: #555;
            font-style: italic;
        }
        
        .date { 
            color: gray;
        }
    
        .lineshow {
            * {
                margin: 0;
                padding: 0;
                box-sizing: border-box;
                font-family: "Quicksand", sans-serif;
            }

            margin: auto;
            margin-top: 20px;
            margin-bottom: 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;           
        }
    
        .description {
            font-size: 16px;
            font-style: italic;
            text-align: center;
            margin-bottom: 20px;
            width: 80%;
            align-self: center;
            margin: auto;

            @media (max-width: 500) {
                width: 100%;
            }
        }
    
        .content {
            font-size: 20px;
            font-weight: normal;
            font-family: Helvetica;

            list-style:disc ;
        }
        
        .comments {
            li {
                list-style: none;
            }
            box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
            margin-top: 30px;
            padding: 50px;
        }
    
        @media (max-width: 700px) {
            .image {
                padding: 10px;
                width: 100%;   
            }
        }
    
        @media (min-width: 700px) {
            padding-top: 50px;
            padding-right: 15%;
            padding-left: 15%;
            padding-bottom: 50px;
            .lineshow {
                width: 80%;
            }
            .image {
                display: block;
                margin-left: auto;
                margin-right: auto;
                margin-top: 20px;
                margin-bottom: 30px;
                max-width: 80%;
            }
        }
        margin-bottom: 50px;
    }
    </style>