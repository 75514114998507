<template>
<div class="blogs-view">
    <BlogCardCollection :blogs="blogs"/>
    <div class="buttonDiv" v-show="notEnd">
        <button class="load-more-button" @click.prevent="downloadNextData">load more</button>
    </div>
    
</div>
</template>

<script>
import BlogCardCollection from '../components/BlogCardCollection.vue';
import "../firebase/firebaseInit";
import db from "../firebase/firebaseInit";
export default {
    name: "blogsview",
    components: {
    BlogCardCollection
},
    data() {
        return {
            blogs: {},
            lastVisible: {},
            next: db.collection("contentRefs").orderBy("date", "desc").limit(20),
        };
    },
    created() {
        this.blogs = {
            title: "Seeing all the blogs",
            blogs: [],
        };
        window.scrollTo(0, 0);
        this.downloadNextData();
    },
    methods: {
        downloadNextData() {
            this.next.get().then((documentSnapshots) => {
                //console.log(documentSnapshots);
                this.lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];
                console.log(this.lastVisible);
                this.next = db.collection("contentRefs").orderBy("date", "desc").startAfter(this.lastVisible).limit(20);
                documentSnapshots.forEach((doc) => {
                    this.blogs.blogs = [...this.blogs.blogs, doc.data()];
                });
            }).catch((err) => {
                console.log(err);
            });
        },    
        handleScroll() {
            //window.removeEventListener("scroll", this.handleScroll);
            if (window.scrollY + window.innerHeight >= document.body.scrollHeight) {
                this.downloadNextData();
            }
            //window.addEventListener("scroll", this.handleScroll);
        },
    },

    mounted() {
        window.scrollTo(0, 0);
        //window.addEventListener("scroll", this.handleScroll);
    },

    updated() {
        this.blogs = {
            title: "Seeing all the blogs",
            blogs: [],
        };
    },
    computed: {
        notEnd() {
            return true;
        }
    }
};
</script>

<style lang="scss" scoped>
.pagelinks {
    text-align: center;
    padding: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.linkclass {
    background-color: #333;
    padding: 10px;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 50%;
    a {
        color: white;
        font-weight: 900;
        text-decoration: none;
        font-size: 18px;
    }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Quicksand", sans-serif;
}

.load-more-button {
    margin: auto;
    padding: 5px 20px 5px 20px;
    font-size: 30px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.load-more-button:hover {
    cursor: pointer;
}

.buttonDiv {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
}
</style>
