import Vue from 'vue'
import Vuex from 'vuex'
import db from "../firebase/firebaseInit";
import firebase from 'firebase';
import "firebase/auth";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    firstName: "",
    lastName: "",
    username: "",
    profileinitials: "",
    email: "",
  },
  mutations: {
    updateUser(state, payload) {
      state.user = payload;
    },
    setProfileInfo(state, doc) {
      //console.log(doc.data());
      state.firstName = doc.data().firstName;  
      state.lastName = doc.data().lastName;
      state.username = doc.data().userName;
      state.email = doc.data().email;
      state.id = doc.id;
    },
    setProfileInitials(state) {
      state.profileinitials = state.firstName.charAt(0) + state.lastName.charAt(0);
    },
  },
  actions: {
    async updateUserInfo({commit}) {
      const dataBase = await db.collection("users").doc(firebase.auth().currentUser.uid);
      const dbResults = await dataBase.get();
      commit("setProfileInfo", dbResults);
      commit("setProfileInitials");
    }
  },
  modules: {
  }
})
