<template>
  <div class="app-wrapper">
    <div class="app">
      <Navigation />
      <router-view />
      <Footer />
    </div>
  </div>
</template>

<script>
import Navigation from "./components/Navigation";
import Footer from "./components/Footer"
import firebase from "firebase";
import "./firebase/firebaseInit"
import "firebase/auth"
export default {
  name: "app",
  components: {
    Navigation,
    Footer,
  },
  
  created() {
    firebase.auth().onAuthStateChanged((user) => {
      this.$store.commit("updateUser",user);
      this.$store.dispatch("updateUserInfo");
      if (user) {
        /*
        console.log("Here we are");
        console.log(user);
        console.log(this.$store.state);
        */
      }
    });
  },
  mounted() {
  },
  methods: {

  },
  watch: {},
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

.arrow {
  margin-left: 8px;
  width: 12px;
  path {
    fill: #000;
  }
}
.arrow-light {
  path {
    fill: #fff;
  }
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container {
  max-width: 1440px;
  margin: 0 auto;
}

.link {
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  color: black;
}

.link-light {
  color: #fff;
}
</style>
