<template>
<header>
    <nav class="container">
        <div class="branding">
            <router-link class="header" :to="{name: 'Home'}">Comperato</router-link>
        </div>
        <div class="nav-links">
            <ul v-show="!mobile">
                <router-link class="link" to="/">Home</router-link>
                <router-link class="link" to="/blogs">Blogs</router-link>
                <div class="dropdown">
                    <div class="dropbtn">
                        <router-link class="link" to="#">Search</router-link>
                    </div>
                    <div class="dropdown-content">
                        <div class="searchDiv">
                            <input
                                class="searchinput"
                                type="text"
                                v-model="searchKeyWord"
                                placeholder="Enter keywords"
                            />
                            <router-link :to="{ name: 'Search', params: { keywords: this.searchKeyWord } }" custom v-slot="{ navigate }">
                                <button class="modal-default-button" @click="navigate" role="link">
                                    Search
                                </button>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div  v-if="!ifUser()" class="dropdown">
                    <div class="dropbtn">
                        <router-link class="link" to="#">Login/Register</router-link>
                    </div>
                    <div class="dropdown-content">
                        <RegisterPopUp/>
                    </div>
                </div>
                <div v-if="ifUser()" class="dropdown">
                    <div class="dropbtn">
                        <router-link class="link initials" to="#">
                            {{ this.$store.state.profileinitials }}
                        </router-link>
                    </div>
                    <div class="dropdown-content">
                        <ProfilePopUp/>
                    </div>
                </div>
            </ul>
        </div>
    </nav>
    <div class="backGroundDim" v-show="mobileNav" v-on:click="toggleMobileNav"></div>
    <menuIcon class="menu-icon" @click="toggleMobileNav" v-show="mobile"/>
    <Transition name="modal"/>
    <transition name="mobile-nav">
        <ul v-show="mobileNav" class="mobile-nav">
            
            <router-link class="link" to="/">Home</router-link>
            <router-link class="link" to="/blogs">Blogs</router-link>
            <div class="dropdown">
                <div class="dropbtn">
                    <router-link class="link" to="#">Search</router-link>
                </div>
                <div class="dropdown-content">
                    <input
                        class="searchinput"
                        type="text"
                        v-model="searchKeyWord"
                        placeholder="Enter keywords"/>
                    <router-link :to="{ name: 'Search', params: { keywords: this.searchKeyWord } }" custom v-slot="{ navigate }">
                        <button class="modal-default-button" @click="navigate" role="link">
                            Search
                        </button>
                    </router-link>
                </div>
            </div>
            
            <div v-if="!ifUser()" class="dropdown">
                <div class="dropbtn">
                    <router-link class="link" to="#">Login/Register</router-link>
                </div>
                <div class="dropdown-content">
                    <RegisterPopUp/>
                </div>
            </div>
            <div v-else class="dropdown">
                <div class="dropbtn">
                    <router-link class="link initials" to="#">
                        {{ this.$store.state.profileinitials }}
                    </router-link>
                </div>
                <div class="dropdown-content">
                    <ProfilePopUp/>
                </div>
            </div>
            
        </ul>
        
    </transition>
</header>
</template>

<script>
import RegisterPopUp from "./RegisterPopUp.vue";
import menuIcon from "../assets/Icons/bars-regular.svg";
import ProfilePopUp from "./ProfilePopUp.vue";
import "../firebase/firebaseInit";
import db from "../firebase/firebaseInit";
import firebase from "firebase/app";
export default {
    name: 'navigation',
    components: {
    menuIcon,
    RegisterPopUp,
    ProfilePopUp
},
    data() {
        return {
            mobile: null,
            mobileNav: null,
            windowWidth: null,
            showModal: false,
            searchKeyWord: "",
        }
    },
    created() {
        window.addEventListener('resize', this.checkScreen);
        this.checkScreen();
    firebase.auth().getRedirectResult().then((result) => {
            console.log(result);
            if (result.additionalUserInfo.isNewUser) {
                const dbElem = db.collection("users").doc(result.user.uid);
                dbElem.set({
                    firstName: result.additionalUserInfo.profile.given_name,
                    lastName: result.additionalUserInfo.profile.family_name,
                    userName: result.additionalUserInfo.profile.name,
                    email: result.additionalUserInfo.profile.email,
                });
                this.$router.push({name: "Home"});
            }
    });
    },
    methods: {
        checkScreen() {
            this.windowWidth = window.innerWidth;
            if (this.windowWidth <= 800) {
                this.mobile = true;
                return;
            } else {
                this.mobile = false;
                this.mobileNav = false;
                return;
            }
        },
        toggleMobileNav() {
            this.mobileNav = !this.mobileNav;
        },
        changeClosed() {
            this.isclosed = !this.isclosed;
        },
        ifUser() {
            //console.log("in navigation");
            //console.log(this.$store.state.user);
            if (this.$store.state.user == null) {
                return false;
            } else {
                return true;
            }
        }

    },
};
</script>

<style lang="scss" scoped>
    * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Quicksand", sans-serif;
}

header {


    background-color: #fff;
    padding: 0 25px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0, 2px, 4px, -1px, rgba(0, 0, 0, 0.06);
    z-index: 99;

    button {
        text-decoration: none;
        border: none;
        background-color: rgba(0, 0, 0, 0.0);
        font-weight: 500;
        font-size: 16px;
    }

    .link {
        font-weight: 500;
        padding: 0 8px;
        transition: 0.3s, color ease;

        &:hover {
            color: #1eb8b8;
        }
    }

    nav {
        display: flex;
        padding: 25px 0;

        .branding {
            display: flex;
            align-items: center;

            .header {
                font-weight: 600;
                font-size: 24px;
                color: #000;
                text-decoration: none;
            }
        }

        .nav-links {
            position: relative;
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: flex-end;

            ul {
                margin-right: 32px;

                .link {
                    margin-right: 32px;
                }

                .link:last-child {
                    margin-right: 0px;
                }
            }
        }
    }

    .menu-icon {
        cursor: pointer;
        position: absolute;
        top: 32px;
        right: 25px;
        height: 25px;
        width: auto;
    }

    .mobile-nav {
        padding: 20px;
        width: 70%;
        max-width: 250px;
        display: flex;
        flex-direction: column;
        position: fixed;
        height: 100%;
        background-color: #303030;
        top: 0;
        left: 0;

        .link {
            padding: 15 0;
            color: #fff;
        }
    }

    .backGroundDim {
        background-color: rgba(0, 0, 0, 0.3);
        height: 100vh;
        position: fixed;
        width: 100vw;
        top: 0;
        left: 0;
    }

    .mobile-nav-enter-active,
    .mobile-nav-leave-active {
        transition: all 1s ease;
    }

    .mobile-nav-enter {
        transform: translateX(-250px);
    }

    .mobile-nav-enter-to {
        transform: translateX(0);
    }

    .mobile-nav-leave-to {
        transform: translateX(-250px);
    }

    // dropdown below here
    .dropbtn {
        padding-bottom: 16px;
        border: none;
    }

    .dropdown {
        position: relative;
        display: inline-block;
    }

    .dropdown-content {
        color: white;
        padding: 10px;
        display: none;
        position: absolute;
        background-color: #303030;
        border: none;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        right: 0;

        @media (max-width: 800px) {
            left: 0;
        }
    }

    .dropdown-content a {
        color: white;
        padding: 12px 16px;
        text-decoration: none;
        display: block;

    }

    .dropdown-content a:hover {
        background-color: #ddd;
    }

    .dropdown:hover .dropdown-content {
        display: block;
    }

    .initials {
        background-color: #303030;
        color: white;
        width: 40px;
        height: 40px;
        display: flex;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        font-weight: 500;
    }
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);

    .searchDiv {
        display: flex;
        margin: 5px;
        flex-direction: column;
    }

    .modal-default-button {
        width: 100%;
        background-color: white;
        padding: 10px;
        font-size: 16px;
        font-weight: 500;
        flex: 2;
        text-decoration: none;
        border: none;
        margin: 5px;    
    }
    
    .modal-default-button:hover {
        background-color: rgba(0, 0, 0, 0);
        cursor: pointer;
        color: white;
    }
    .searchinput {
        width: 100%;
        background-color: #fff;
        color: #000;
        text-decoration: none;
        outline: none;
        border: none;
        padding: 10px;
        margin: 5px;
    }
}
</style>