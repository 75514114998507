<template>
<div class="register-page">
    <div class="register-form">
        <h3>Register to Comperato</h3>
    </div>
{{ userCred }}
    <div class="register-form">
        <div class="register-item">
            <p>Name:</p>
            <input type="text" name="" id="" v-model="name">
        </div>
        <div class="register-item">
            <p>Surname:</p>
            <input type="text" name="" id="" v-model="surname">
        </div>
        <div class="register-item">
            <p>Nickname:</p>
            <input type="text" name="" id="" v-model="nickname">
        </div>
        <div class="register-item">
            <p>Email:</p>
            <input type="email" name="" id="" v-model="email">
        </div>
        <div class="register-item">
            <p>Password:</p>
            <input type="password" name="" id="" v-model="password">
        </div>
        <div class="register-item">
            <p>Password again:</p>
            <input type="password" name="" id="" v-model="passwordAgain">
        </div>
    </div>
    <div v-show="error" class="error">
        {{ errorMessage }}
    </div>
    <div v-show="message" class="message">
        {{ messageContent }}
    </div>
    <div class="register-form">
        <div class="register-item">
            <button @click.prevent="googleRegister">Log in with google</button>        
        </div>
        <div class="register-item">
            <button @click.prevent="register">Create new account</button>        
        </div>
    </div>
</div>
</template>

<script>
import "../firebase/firebaseInit";
import db from "../firebase/firebaseInit";
import firebase from "firebase/app";
import "firebase/auth";
export default {
    name: "RegisterPage",
    data() {
        return {
            name: "",
            surname: "",
            password: "",
            passwordAgain: "",
            email: "",
            error: false,
            errorMessage: "",
            message: false,
            messageContent: "",
            nickname: "",
            userCred: null,
        };
    },
    methods: {
        checkPass() {
            if (this.password === this.passwordAgain) {
                return true;
            } else {
                return false
            }
        },
        async register() {
            if (
                this.name !== "" &&
                this.surname !== "" &&
                this.password !== "" &&
                this.passwordAgain !== "" &&
                this.email !== "" &&
                this.nickname !== "" &&
                this.checkPass()
            ) {
                await firebase.auth().createUserWithEmailAndPassword(this.email, this.password);
                firebase.auth().currentUser.sendEmailVerification().then(() => {
                    console.log(firebase.auth().currentUser);
                    this.message = true;
                    this.messageContent = "Verfication email was sent to " + this.email;
                });
                const dbElem = db.collection("users").doc(firebase.auth().currentUser.uid);
                await dbElem.set({
                    firstName: this.name,
                    lastName: this.surname,
                    userName: this.nickname,
                    email: this.email,
                });
                console.log("create db.");
                this.error = false;
                this.errorMessage = "";
                return;
            }
            this.error = true;
            this.errorMessage = "error happened";
        },
        async googleRegister() {
            firebase.auth().signInWithRedirect(new firebase.auth.GoogleAuthProvider());
            
            var userCred = await firebase.auth().getRedirectResult();
            console.log(userCred);
            
        }
    },
    created() {
        firebase.auth().getRedirectResult().then((result) => {
            console.log(result);
            if (result.additionalUserInfo.isNewUser) {
                const dbElem = db.collection("users").doc(result.user.uid);
                dbElem.set({
                    firstName: result.additionalUserInfo.profile.given_name,
                    lastName: result.additionalUserInfo.profile.family_name,
                    userName: result.additionalUserInfo.profile.name,
                    email: result.additionalUserInfo.profile.email,
                });
                this.$router.push({name: "Home"});
            }
        });
    }

};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Quicksand", sans-serif;
}

.register-page {
    margin: auto;
    margin-top: 30px;
    align-content: center;
    text-align: center;
    width: 100%;
    padding: 30px;

    .message {
        color: green;
        background-color: #FFFFFF;
        font-weight: 500;
        margin-top: 15px;
        margin-bottom: 15px;
        font-size: larger;
        padding: 5px;
        border-radius: 10px;
    }

    .error {
        color: red;
        background-color: #FFFFFF;
        font-weight: 500;
        margin-top: 15px;
        margin-bottom: 15px;
        font-size: larger;
        padding: 5px;
        border-radius: 10px;
    }

    input {
        text-decoration: none;
        border: none;
        outline: none;
        padding: 5px;
    }

    button {
        text-decoration: none;
        border: none;
        outline: none;
        flex: 1;
        font-size: 18px;
        font-weight: 500;
        padding: 15px;
        font: bolder;
    }

    button:hover {
        background-color: rgba($color: #FFFFFF, $alpha: 0.2);
        color: white;
    }

    .register-form {
        margin: auto;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        background-color: #303030;
        color: white;
        font-size: 24px;
        font-weight: 500;

        padding: 30px;  

        width: 50vh;

        .register-item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 10px;
            padding-top: 20px;
        }

        @media (max-width: 500px) {
            width: 100%;
            padding: 20px;

            .register-item {
                flex-direction: column;
            }
        }

    }

    @media (max-width: 500px) {
        width: auto;
        padding: 0;
    }
}
</style>