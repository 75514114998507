<template>
    <div class="register-page">
        <div class="register-form">
            <h3>Reset Password</h3>
        </div>
    
        <div class="register-form">
            <div class="register-item">
                <p>New Password:</p>
                <input type="text" name="" id="" v-model="newPass1">
            </div>
            <div class="register-item">
                <p>New Again:</p>
                <input type="text" name="" id="" v-model="newPass2">
            </div>        
        </div>
        <div class="error" v-show="passwordMatch"> {{ passwordMatch }}</div>
        <div class="error" v-show="this.error">{{ this.error }}</div>
        <div class="register-form">
            <div class="register-item">
                <button @click.prevent="resetPassword">Reset Password</button>        
            </div>
        </div>
    </div>
    </template>
    
    <script>
    import "../firebase/firebaseInit";
    import firebase from "firebase/app";
    import "firebase/auth"
    export default {
        name: "resetPassword",
        data() {
            return {
                newPass1: "",
                newPass2: "",
                error: null,
            };
        },
        methods: {
            resetPassword() {
                const actionCode = this.$route.query.oobCode;
                const mode = this.$route.query.mode;
                if (this.passwordMatch != false) {
                    //this.error = "No proceed;"
                    return;
                }
                
                else if (mode == "resetPassword") {
                    console.log("mark 1")
                    firebase.auth().verifyPasswordResetCode(actionCode).then((email) => {
                        var accountEmail = email;
                        //console.log(email);
                        var newPassword = this.newPass1;
                        firebase.auth().confirmPasswordReset(actionCode, newPassword).then((resp) => {
                            console.log(resp);
                        }).catch((err) => {
                            console.log(err);
                            this.error = err.message;
                        });
                        

                        // sign in here
                        //console.log(accountEmail);
                        firebase.auth().signInWithEmailAndPassword(accountEmail, newPassword).then(() => {
                            this.$router.push({name: "Home"});
                        }).catch((err) => {
                            this.error = err.message;
                            console.log(err);
                        })

                        console.log("done")
                    }).catch((err) => {
                        console.log(err);
                        this.error = err.message;
                    });
                }
                
            }
        },
        computed: {
            passwordMatch() {
                if (this.newPass1 != this.newPass2) {
                    return "Passwords don't match";
                } else {
                    return false;
                }
            }
        }
    };
    </script>
    
    <style lang="scss" scoped>
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: "Quicksand", sans-serif;
    }

    .register-page {
        margin: auto;
        margin-top: 30px;
        align-content: center;
        text-align: center;
        width: 100%;
        padding: 30px;
    
        .error {
            color: red;
            width: 50vh;
            margin: auto;
            padding: 10px;
        }

        .message {
            color: green;
        }

        input {
            text-decoration: none;
            border: none;
            outline: none;
            padding: 5px;
        }
    
        button {
            text-decoration: none;
            border: none;
            outline: none;
            flex: 1;
            font-size: 18px;
            font-weight: 500;
            padding: 15px;
            font: bolder;
        }
    
        button:hover {
            background-color: rgba($color: #FFFFFF, $alpha: 0.2);
            color: white;
        }
    
        .register-form {
            margin: auto;
            margin-bottom: 10px;
            display: flex;
            flex-direction: column;
            background-color: #303030;
            color: white;
            font-size: 24px;
            font-weight: 500;
    
            padding: 30px;  
    
            width: 50vh;
    
            .register-item {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 10px;
                padding-top: 20px;
            }

            @media (max-width: 500px) {
                width: 100%;
                padding: 20px;
                .register-item {
                    display: flex;
                    flex-direction: column;
                    text-align: left;
                }
            }
    
        }
    }
    </style>