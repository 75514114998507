<template>
<div class="blog-card-collection">
    <div class="container">
        <h1>{{ blogs.title }}</h1>
        <div class="blog-cards">
            <BlogCard :blog="blog" v-for="(blog, index) in blogs.blogs" :key="index"/>
        </div>
    </div>
</div>
</template>

<script>
import BlogCard from './BlogCard.vue';
export default {
    name: "blogcard",
    props: ["blogs"],
    components: {
        BlogCard,
    },
};
</script>

<style lang="scss" scoped>

.blog-card-collection {

h1 {
    padding: 30px;
    padding-left: 0;
}

  position: relative;
  padding: 80px 16px;
  background-color: #f1f1f1;
  @media (min-width: 500px) {
    padding: 100px 16px;
  }
  .blog-cards {
    display: grid;
    gap: 32px;
    grid-template-columns: 1fr;
    @media (min-width: 500px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: 900px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (min-width: 1200px) {
      grid-template-columns: repeat(4, 1fr);
    }
  
}
}
</style>