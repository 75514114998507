<template>
<div class="div blog-page">

</div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.blog-page {
    color: black;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Quicksand", sans-serif;
}
</style>