<template>
<transition name="modal" class="register-pop-up">
    <div>
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">

                    <div class="modal-header">
                        <slot name="header">
                        </slot>
                    </div>

                    <div class="modal-body">
                        <slot name="body">
                            <div class="modal-body-item">
                                <p>Email</p>
                                <input type="text" name="" id="" v-model="email">
                            </div>
                            <div class="modal-body-item">
                                <p>Password</p>
                                <input type="password" name="" id="" v-model="password">
                            </div>
                        </slot>
                    </div>

                    <div v-show="error"  class="error">
                        {{ errorMessage }}
                    </div>

                    <div class="modal-footer">
                        <slot name="footer">
                            <router-link to="/resetpass" custom v-slot="{ navigate }">
                                <button class="modal-default-button" @click="navigate" role="link">
                                    forgot password
                                </button>
                            </router-link>
                            <div class="footer-bottom">
                                <button class="modal-default-button" @click.prevent="signIn">
                                    Sign-in
                                </button>
                                <button class="modal-default-button" @click.prevent="signInWithGoogle">
                                    with Google
                                </button>
                            </div>
                            <router-link to="/register" custom v-slot="{ navigate }">
                                <button class="modal-default-button" @click="navigate" role="link">
                                    Don't have an account
                                </button>
                            </router-link>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</transition>
</template>

<script>
import "../firebase/firebaseInit";
import firebase from "firebase/app";
import "firebase/auth"
export default {
    name: "registerpopup",
    data() {
        return {
            error: false,
            errorMessage: "",
            email: "",
            password: "",
        };
    },
    methods: {
        signIn() {
            firebase.auth().signInWithEmailAndPassword(this.email, this.password).then(() => {
                this.$router.push({name: "Home"});
                this.error = false;
                this.errorMessage = "";
                console.log(firebase.auth().currentUser);
            }).catch((err) => {
                this.error = true;
                this.errorMessage = err;
                console.log(err.message);
            });
            return;
        },
        signInWithGoogle() {
            firebase.auth().signInWithRedirect(new firebase.auth.GoogleAuthProvider());
        },
    },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Quicksand", sans-serif;
}

.error {
    width: inherit;
}


.modal-header {
    background-color: #303030;
    color: white;
    font-size: 16px;
}

slot {
    padding: 5px;
}

.modal-mask {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
  background-color: #303030;
  
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  //width: 400px;
  left: 0;
  margin: 0px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  background-color: #303030;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
  display: flex;
  flex-direction: column;

  .modal-body-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 16px;
    padding-bottom: 5px;
    font-weight: 500;
    color: white;  
    background-color: #303030;

    input {
        text-decoration: none;
        outline: none;
        border: none;
        padding: 10px;
    }

    p {
        padding: 10px;
    }
  }
}

.modal-footer {
    display: flex;
    flex-direction: column;
}

.footer-bottom {
    display: flex;
    flex-direction: row;
}

.modal-default-button {
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
    flex: 2;
    text-decoration: none;
    border: none;
    margin: 5px;    
}

.modal-default-button:hover {
    background-color: rgba(0, 0, 0, 0);
    cursor: pointer;
    color: white;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>