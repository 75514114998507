<template>
<div class="comment-card" v-if="!deleted">
    <div>
        <div class="name-date">
            <p v-if="showDelete" class="commenter">You</p>
            <p v-else class="commenter"> {{ this.comment.data().username }} </p>
            <p class="comment-date"> {{ this.comment.data().date.toDate().toDateString() }} </p>
            <button class="button-comment-delete" v-if="showDelete" @click.prevent="deleteComment">X</button>
        </div>
        <hr class="under-commenter">
        <p class="comment-content">{{ this.comment.data().content }}</p>
    </div>
</div>
</template>

<script>
import db from "../firebase/firebaseInit";
import "../firebase/firebaseInit";
export default {
    props: ["comment"], 
    data() {
        return {
            deleted: false,
        };
    },
    computed: {
        showDelete() {
            if (
                this.comment.data().userId == this.$store.state.id &&
                this.comment.data().userId != null
                ) {
                //console.log(this.comment.userId);
                return true;
            } else { 
                return false;
            }
            
        },
    },
    methods: {
        deleteComment() {
            if (confirm("Are you sure to delete that comment!")) {
                console.log("Content will be deleted");
                this.certainDeletion();
                return true;
            } else {
                console.log("Content will not be deleted");
                return false;
            }
        },
        certainDeletion() {
            const docRef = db.collection("contents").doc(this.$route.query.id).collection("comments").doc(this.comment.id);
            docRef.delete().then(() => {
                alert("Your comment succesfully deleted!");
                this.deleted = true
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.comment-card {
    color: default;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #303030;
    margin-top: 20px;

    .name-date {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .commenter {
        color: #303030;
        font-weight: 600;
        font-size: 16px;
        padding: 5px;
        margin-right: 5px;
    }

    .comment-date {
        color: gray;
        font-size: 12px;
        padding: 5px;
        align-self: center;
    }

    .under-commenter {
        width: 20%;
        margin: 5px;
    }

    .button-comment-delete {
        display: block;
        margin-left: auto;
        margin-right: 0;
        background-color: #303030;
        color: white;
        font-size: 16px;
        font-weight: 900;
        padding: 10px;
        border: 0px solid white;
        border-radius: 50%;
        align-self: center;
    }

    .button-comment-delete:hover {
        opacity: 0.7;
        cursor: pointer;
    }

    .comment-content {
        font-size: 16px;
        color: #303030;
        font-weight: 500;
        padding: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}
</style>