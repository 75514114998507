<template>
<div class="blogpost" :class="{ 'no-user': !user && post.welcomeScreen}" >
    <div class="blog-content">
      <div>
        <h2 v-if="post.welcomeScreen">{{ post.title }}</h2>
        <h2 v-else>{{ postData.title }}</h2>
        <p v-if="post.welcomeScreen">{{ post.desc }}</p>
        <p class="content-preview" v-else v-html="postData.desc"></p>
      
          <router-link class="link " v-if="post.welcomeScreen" to="/register">
            Login/Register<Arrow class="arrow arrow-light" />
          </router-link>
          <router-link class="link" v-else :to="{ name: 'ViewBlog', params: { topicname: this.postData.topic, blogname: this.getBlogName}, query: {id: this.postData.contentLink} }">
            View The Post<Arrow class="arrow" />
          </router-link>
      
        
      </div>
    </div>
    <div class="blog-photo">
      <img v-if="post.welcomeScreen" :src="require(`../assets/blogPhotos/${post.coverPhoto}`)" :alt="post.title" />
      <img v-else :src="postData.coverPhoto" :alt="postData.title" />
    </div>
</div>
</template>

<script>
import "../firebase/firebaseInit";
import db from "../firebase/firebaseInit";
import Arrow from "../assets/Icons/arrow-right-light.svg";
export default {
    name: "blogPost",
    props: ["post"],
    components: {
        Arrow
    },
    data() {   
      return {
        postData: {},
        user: {},
      };
    },

    created() {
      this.getData(this.post.ref);
    },

    methods: {
      getData(ref) {
        db.collection("contentRefs").doc(ref).onSnapshot((doc) => {
          this.postData = doc.data();
        });
      }
    },

    computed: {
      getBlogName() {
        return this.postData.title.split(" ").join("-").toLowerCase();
      }
    }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Quicksand", sans-serif;
}
.blogpost {
    position: static;
    margin-top: 10px;

    img { 
        background-color: #ADADAD;
    }

    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    @media (min-width: 700px) {
        min-height: 650px;
        max-height: 650px;
        flex-direction: row;
    }

    .blog-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 4;
    order: 2;
    @media (min-width: 700px) {
      order: 1;
    }
    @media (min-width: 800px) {
      flex: 3;
    }
    div {
      max-width: 375px;
      padding: 72px 24px;
      @media (min-width: 700px) {
        padding: 0 24px;
      }
      h2 {
        font-size: 32px;
        font-weight: 300;
        text-transform: uppercase;
        margin-bottom: 24px;
        @media (min-width: 700px) {
          font-size: 40px;
        }
      }
      p {
        font-size: 15px;
        font-weight: 300;
        line-height: 1.7;
      }
      .content-preview {
        font-size: 13px;
        max-height: 24px;
        width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .link {
        display: inline-flex;
        align-items: center;
        margin-top: 32px;
        padding-bottom: 4px;
        border-bottom: 1px solid transparent;
        transition: 0.5s ease-in all;
        &:hover {
          border-bottom-color: #303030;
        }
      }
      .link-light {
        &:hover {
          border-bottom-color: #ffff;
        }
      }
    }
  }
  .blog-photo {
    order: 1;
    flex: 3;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    @media (min-width: 700px) {
      order: 2;
    }
    @media (min-width: 800px) {
      flex: 4;
    }
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  /*
  &:nth-child(even) {
    .blog-content {
      order: 2;
    }
    .blog-photo {
      order: 1;
    }
  }
  */
}

.no-user {
  .blog-content {
    background-color: #303030;
    color: #fff;
  }
    
}



</style>