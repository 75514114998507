import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Blogs from "../views/Blogs.vue";
import Register from "../views/Register.vue";
import ResetPassword from "../views/ResetPassword.vue";
import BlogPage from "../views/BlogPage.vue";
import Profile from "../views/Profile.vue";
import ViewBlog from "../views/ViewBlog.vue";
import Search from "../views/Search.vue";
import Contact from "../views/Contact.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import VueMeta from "vue-meta";

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Home",
      description: "Comperato is a website where two subject compared. You may find content on various subjects of your interest.",
      keywords: "comperato, compare, collide, blog, comparing, debate, discuss, better, worst",
    }
  },
  {
    path: "/blogs",
    name: "Blogs",
    component: Blogs,
    meta: {
      title: "Blogs",
      description: "All the blogs in the comperato.com given in this page."
    }
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: {
      title: "Register",
      description: "Register to the comperato website in this link."
    },
  },
  {
    path: "/resetpass",
    name: "ResetPassword",
    component: ResetPassword,
    meta: {
      title: "Reset Password",
      description: "Did you forget your password to enter comperato. Here you can reset your password."
    },
  },
  {
    path: "/Content",
    name: "BlogPage",
    component : BlogPage,
    meta: {
      title: "Content",
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component : Profile,
    meta: {
      title: "profile",
      description: "Welcome to your comperato profile."
    },
  },
  {
    path: "/blog/:topicname/:blogname",
    name: "ViewBlog",
    component: ViewBlog,
    meta: {
      title: "View",
      name: ""
    },
  },
  {
    path: "/search/:keywords",
    name: "Search",
    component: Search,
    meta: {
      title: "Search",
    },
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
    meta: {
      title: "Contact",
      description: "You want to reach Comperato team. This is the contact link for compeato team."
    }
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: {
      title: "Forgot password",
    },
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {x:0,y:0};
  }
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}-Comperato`;
  next();
});


export default router;
