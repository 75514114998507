<template>
    <div class="register-page">
        <div class="register-form">
            <h3>Reset Password</h3>
        </div>
    
        <div class="register-form">
            <div class="register-item align-left">
                <p>Email:</p>
                <input type="text" name="" id="" v-model="email">
            </div>
        </div>
        <div class="error" v-show="error">{{ errorMessage }}</div>
        <div class="message" v-show="message">{{ messageContent }}</div>
        <div class="register-form">
            <div class="register-item">
                <button @click.prevent="sendResetMail">Send email</button>        
            </div>
        </div>
    </div>
    </template>
    
    <script>
    import "../firebase/firebaseInit";
    import firebase from 'firebase/app';
    import "firebase/auth";
    export default {
        name: "resetPassword",
        data() {
            return {
                email: "",
                message: false,
                messageContent: "",
                error: false,
                errorMessage: "",
            };
        },
        methods: {
            sendResetMail() {
                firebase.auth().sendPasswordResetEmail(this.email)
                .then(() => {
                    this.message = true;
                    this.messageContent = "Message sent succesfuly to " + this.email;
                })
                .catch((err) => {
                    this.error = true;
                    this.errorMessage = err.message
                    //var errorCode = err.code;
                    //var errorMessage = err.message;
                });
            },
        },
    };
    </script>
    
    <style lang="scss" scoped>

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Quicksand", sans-serif;
}

    .register-page {

        margin: auto;
        margin-top: 30px;
        align-content: center;
        text-align: center;
        width: 100%;
        padding: 30px;
    
        .error {
            color: red;
        }

        .message {
            color: green;
        }

        input {
            text-decoration: none;
            border: none;
            outline: none;
            padding: 5px;
        }
    
        button {
            text-decoration: none;
            border: none;
            outline: none;
            flex: 1;
            font-size: 18px;
            font-weight: 500;
            padding: 15px;
            font: bolder;
        }
    
        button:hover {
            background-color: rgba($color: #FFFFFF, $alpha: 0.2);
            color: white;
        }
    
        .register-form {
            margin: auto;
            margin-bottom: 10px;
            display: flex;
            flex-direction: column;
            background-color: #303030;
            color: white;
            font-size: 24px;
            font-weight: 500;
    
            padding: 30px;  
    
            width: 50vh;

    
            .register-item {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 10px;
                padding-top: 20px;
            }

            @media (max-width: 500px) {
                width: 100%;
                padding: 20px;

                .register-item {
                    flex-direction: column;
                    justify-content: flex-start;
                }

                .align-left {
                    text-align: left;
                    p {
                        margin-bottom: 10px;
                    }
                }
            }
    
        }
    }
    </style>