<template>
<div class="post-list">
    <h1>Highligths</h1>
    <li  v-for="(item, index) in posts" :key="item.id">
        <BlogPost :post="item" :key="index"/>
    </li>
</div>
</template>

<script>
import BlogPost from './BlogPost.vue';
export default {
    name: "postlister",
    props: ["posts"],
    components: {
        BlogPost,
    },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Quicksand", sans-serif;
}

.post-list {
    padding: 50px;
    li {
        width: 80%;
        margin: auto;
        
        list-style-type: none;
        padding: 30px;
    }

    h1 {
        padding: 30px;
        margin-left: 10%;
    }

    

    @media (max-width: 700px) {
        padding: 20px;
        li {
            padding: 20px;
            width: 100%;    
        }
        h1 {
            padding: 20px;
            margin-left: 0;
        }
    }
}
</style>