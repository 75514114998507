// Import the functions you need from the SDKs you need
import firebase from "firebase/app";
import "firebase/firestore";
//import "firebase/compat/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAbm4JP_E5oW8BwSgqDIsUEE9HOtQxxcb8",
  authDomain: "comperato-training.firebaseapp.com",
  projectId: "comperato-training",
  storageBucket: "comperato-training.appspot.com",
  messagingSenderId: "448843571089",
  appId: "1:448843571089:web:e35e2f50498482e0740430",
  measurementId: "G-445CT7MGXE"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

const timestamp = firebase.firestore.FieldValue.serverTimestamp;
//const analytics = firebase.analytics();

export {timestamp};
export default app.firestore();