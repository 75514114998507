<template>
<div class="main-slider">
    <div class="slider">
      <div class="slides">
        <div v-for="(item, index) in this.slidedata" :key="item.id">
            <SlideItem :post="item" :key="index"/>
        </div>
        
      </div>
        <!--
        <a href="#slide-1">1</a>
        <a href="#slide-2">2</a>
        <a href="#slide-3">3</a>
        <a href="#slide-4">4</a>
        <a href="#slide-5">5</a>  
        -->
    </div>
</div>
</template>

<script>
import "../firebase/firebaseInit";
import db from "../firebase/firebaseInit";
import SlideItem from "../components/SlideItem.vue";
export default {
    components: {
        SlideItem,
    },

    data () {
        var lst = []

        db.collection("slider").limit(5)
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                lst.push(doc.data())
                //console.log(doc.id, " => ", doc.data());
            });
        })
        .catch((error) => {
            console.log("Error getting documents: ", error);
        });

        return {
            slidedata: lst,
        }
    },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Quicksand", sans-serif;
}

.main-slider {
    width: 100%;
    height: 50vh;

    .slides > div {
        scroll-snap-align: start;
        flex-shrink: 0;
        width: 100%;
        height: 50vh;
        background: #eee;
        transform-origin: center center;
        transform: scale(1);
        transition: transform 0.5s;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 100px;

        animation-name: moveSlideshow;
        animation-duration: 25s;
        animation-delay: 0;
        animation-direction: alternate;
        animation-iteration-count: infinite;
        animation-fill-mode: forwards;
        animation-timing-function: ease;
    }

    @media (min-width: 700px) {
        height: 500px;
        .slides > div {
            height: 500px;
        }
    }
    .slider {
        width: 100%;
        text-align: center;
        overflow: hidden;
    }
    .slides {
        width: 98%;
        position: absolute;
        display: flex;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;
    }

    .slides::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
    .slides::-webkit-scrollbar-thumb {
        background: #666;
        border-radius: 10px;
    }
    .slides::-webkit-scrollbar-track {
        background: transparent;
    }
    
    .slider > a {
        display: inline-flex;
        width: 1.5rem;
        height: 1.5rem;
        background: #303030;
        color: white;
        text-decoration: none;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin: 1rem 0.5rem 0.5rem 0.5rem;
        position: relative;
        vertical-align: bottom;
    }
    .slider > a:active {
        top: 1px;
        color: #1c87c9;
    }
    .slider > a:focus {
        background: #eee;
    }    

    @keyframes moveSlideshow {
        0% {
            transform: translateX(0%);
        }
        25% {
            transform: translateX(-100%);
        }
        50% {
            transform: translateX(-200%);
        }
        75% {
            transform: translateX(-300%);
        }
        100% {
            transform: translateX(-400%);
        }
    }
}
</style>