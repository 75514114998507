<template>
<div class="searchDiv">
    <BlogCardCollection :blogs="blogs"/>
</div>
</template>

<script>
import BlogCardCollection from "../components/BlogCardCollection.vue";
import "../firebase/firebaseInit";
import db from "../firebase/firebaseInit";
export default {
    components: {
        BlogCardCollection,
    },
    data() {
        var blgid = this.$route.params.keywords;
        blgid = blgid.split(" ");
        console.log(blgid);
        return {
            blogs: {
                title: "Seach page",
                blogs: []
            },
        };
    },
    created() {
        this.downloadNextData();
    },
    watch: {
        '$route' () {
            this.downloadNextData();
        }
    },
    methods: {
        downloadNextData() {
            this.blogs.blogs = [];
            var blgid = this.$route.params.keywords.toLowerCase();
            blgid = blgid.split(" ");
            db.collection("contentRefs").where('labels', 'array-contains-any', blgid).limit(60).get().then((documentSnapshots) => {
                documentSnapshots.forEach((doc) => {
                    this.blogs.blogs = [...this.blogs.blogs, doc.data()];
                });
            })
        },    
        handleScroll() {
            if (window.scrollY + window.innerHeight >= document.body.scrollHeight - 50) {
                //this.downloadNextData();
            }
        },
    },

    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
}
</script>

<style lang="scss" scoped>

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Quicksand", sans-serif;
}

.searchDiv {
    color: default;
}
</style>