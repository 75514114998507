<template>
<div class="profile">
    <div class="register-form">
        <h3>Your Profile</h3>
    </div>

    <div class="register-form">
        <h3>{{ this.$store.state.firstName }} {{ this.$store.state.lastName }}</h3>
    </div>

    <div class="register-form">
        <div class="register-item">
            <p>Name:</p>
            <input type="text" name="" id="" v-model="name">
        </div>
        <div class="register-item">
            <p>Surname:</p>
            <input type="text" name="" id="" v-model="surname">
        </div>
        <div class="register-item">
            <p>Nickname:</p>
            <input type="text" name="" id="" v-model="nickname">
        </div>
        <div class="register-item">
            <p>Email:</p>
            <input type="email" class="read-only" name="" id="" v-model="email" readonly>
        </div>
    </div>
    <div v-show="error" class="error">
        {{ errorMessage }}
    </div>
    <div v-show="message" class="message">
        {{ messageContent }}
    </div>
    <div class="register-form">
        <div class="register-item">
            <button @click.prevent="updateData">Update</button>        
        </div>
        <!--
        <div class="register-item">
            <button @click.prevent="register">Reset Password</button>        
        </div>
        -->
    </div>
</div>
</template>

<script>
import "../firebase/firebaseInit";
import db from "../firebase/firebaseInit";
import firebase from "firebase/app";
import "firebase/auth";
export default {
    name: "Profile",
    data() {
        return {
            name: this.$store.state.firstName,
            surname: this.$store.state.lastName,
            password: "",
            passwordAgain: "",
            email: this.$store.state.email,
            error: false,
            errorMessage: "",
            message: false,
            messageContent: "",
            nickname: this.$store.state.username,
        };
    },
    methods: {
        updateData() {
            console.log(this.$store.state.email);
            db.collection("users").where("email", "==", this.$store.state.email).get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    db.collection("users").doc(doc.id).set({
                        firstName: this.name,
                        lastName: this.surname,
                        userName: this.nickname,
                    }, { merge: true }).then(() => {
                        this.message = true;
                        this.$store.dispatch("updateUserInfo");
                        this.messageContent = "Data updated succesfully";
                    }).catch((err) => {
                        this.error = true;
                        this.errorMessage = err.message;
                    });
                });
            });
        },
        resetPassword() {
            firebase.auth().sendPasswordResetEmail(this.email)
            .then(() => {
                this.message = true;
                this.messageContent = "Message sent succesfuly to " + this.email;
            })
            .catch((err) => {
                this.error = true;
                this.errorMessage = err.message
                //var errorCode = err.code;
                //var errorMessage = err.message;
            });
        }
    },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Quicksand", sans-serif;
}


.profile {
    margin: auto;
    margin-top: 30px;
    align-content: center;
    text-align: center;
    width: 100%;
    padding: 30px;

    @media (max-width: 500px) {
        width: 90%;
        padding: 0;
    }

    .message {
        color: green;
        background-color: #FFFFFF;
        font-weight: 500;
        margin-top: 15px;
        margin-bottom: 15px;
        font-size: larger;
        padding: 5px;
        border-radius: 10px;
    }

    .error {
        color: red;
        background-color: #FFFFFF;
        font-weight: 500;
        margin-top: 15px;
        margin-bottom: 15px;
        font-size: larger;
        padding: 5px;
        border-radius: 10px;
    }

    input {
        text-decoration: none;
        border: none;
        outline: none;
        padding: 5px;
    }

    .read-only {
        background-color: rgba($color: #FFFFFF, $alpha: 0.4);
    }

    button {
        text-decoration: none;
        border: none;
        outline: none;
        flex: 1;
        font-size: 18px;
        font-weight: 500;
        padding: 15px;
        font: bolder;
    }

    button:hover {
        background-color: rgba($color: #FFFFFF, $alpha: 0.2);
        color: white;
    }

    .register-form {
        margin: auto;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        background-color: #303030;
        color: white;
        font-size: 24px;
        font-weight: 500;

        padding: 30px;  

        width: 50vh;

        .register-item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 10px;
            padding-top: 20px;
        }

        @media (max-width: 500px) {
            width: 100%;
            padding: 20px;

            .register-item {
                flex-direction: column;
                justify-content: flex-start;
            }
        }

    }
}
</style>